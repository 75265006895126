  <template>
    <h2>Programme</h2>
    
    <div class="programme">
      <template v-for="(event, index) in events" :key="index">
        <div class="event"  v-if="formData.selectedEvents.includes(index)">
          <div class="name">{{ event.name }}</div>
          <div class="price">
            <span class="regular">{{ event[formData.tarifs] }}€</span>
          </div>
        </div>
      </template>

      <div v-if="promoInfos" class="promo" :class="promoInfos.expired ? 'expired' : ''">
        <div class="expired-message" v-if="promoInfos.expired">
          <p>Code promo expiré</p>
          <p style="text-decoration:line-through">-10€</p>
        </div>
        <div v-else>
          <div class="promo-price" v-if="checkPromoActive()">
            <p>Réduction {{ promoInfos.timeLeft }}</p>
            <p>-10€</p>
          </div>
          <div class="promo-price" v-else>
            <p>Réduction non applicable</p>
            <p style="text-decoration:line-through">-10€</p>
          </div>
          
        </div>
      </div>

      <div class="total">
        <span class="total-label">Total</span>
        <span class="total-price">{{ totalPrice() }}€</span>
      </div>
    </div>
    
    <div class="checkbox">
      <label for="international">Payement depuis l'étranger</label>
      <input type="checkbox" id="international" v-model="formData.international" @change="changeDatas">
    </div>

    <div class="coordonnees">
      <h2>Coordonnées</h2>
      <div class="infos"><i>Nom :</i> <strong>{{ formData.firstname }} {{ formData.lastname }}</strong></div>
      <div class="infos"><i>Email :</i> <strong>{{ formData.email }}</strong></div>
      <div class="infos"><i>Téléphone :</i> <strong>{{ formData.phone }}</strong></div>
      <div class="infos"><i>Section :</i> <strong>{{ formData.section }}</strong></div>
      <div class="infos"><i>FirstTimer :</i> <strong>{{ formData.firsttimer? "Oui" : "Non" }}</strong></div>
      <div class="infos"><i>Adresse :</i> <strong>{{ formData.address }}<br />{{ formData.postalCode }} {{ formData.city }}</strong></div>
    </div>
    <div class="invoice" v-if="formData.invoice">
      <h2>Facture</h2>
      <div class="infos" v-if="formData.invoice"><i>Nom :</i> <strong>{{ formData.invoiceName }}</strong></div>
      <div class="infos" v-if="formData.invoice && formData.invoiceTVA"><i>TVA :</i> <strong>{{ formData.invoiceTVA }}</strong></div>
      <div class="infos" v-if="formData.invoice"><i>Adresse :</i> <strong>{{ formData.invoiceAddress }}<br />{{ formData.invoicePostalCode }} {{ formData.invoiceCity }}</strong></div>
    </div>

    <div class="actions">
      <router-link class="prevstep" to="/coordonnees">Retour</router-link>
      <span class="nextstep" @click="handleSubmit">Payer</span>
    </div>
  </template>
  
  <script>
  import { loadStripe } from '@stripe/stripe-js';
  import axios from 'axios';
  
  export default {
    props: {
      formData: {
        type: Object,
        required: true
      },
      events: {
        type: Object,
        required: true
      },
      promoInfos: {
        type: Object,
        required: true
      },
      updateDatasUser: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        
      };
    },
    created() {
      if (!this.formData.firstname || !this.formData.lastname || !this.formData.email || !this.formData.phone || !this.formData.section || !this.formData.address || !this.formData.postalCode || !this.formData.city) {
        this.$router.push('/coordonnees');
      }
    },
    methods: {
      changeDatas() {
        this.updateDatasUser(this.formData);
        console.log(this.formData);
      },
      totalPrice() {
        let total = 0;
        this.formData.selectedEvents.forEach(event => {
          total += this.events[event][this.formData.tarifs];
        });
        if(this.checkPromoActive()){
          total -= 10;
        }
        return total;
      },
      checkPromoActive(){
        if(this.promoInfos && !this.promoInfos.expired) {
          // uniquement si le programme complet ou samedi jouenée est sélectionné
          if (this.formData.selectedEvents.includes("complet") || this.formData.selectedEvents.includes("samedicomplet")){
            return true;
          }
        }
        return false;
      },
      async handleSubmit() {
        const stripe = await loadStripe('pk_live_51QCR4nP6mu6se3yf2Yku6JDcPaCHI1vVFDa2EkV8Oza0Ksw10vk4SLF43yjLyUYFRq3rmBJRDuhrOwRMCVKsyaxv00PIE4rcN7');
  
        // Création de la session de paiement
        const { data: session } = await axios.post('/api/create-checkout-session.php', {
          formData: this.formData
        });
  
        // Redirection vers Stripe
        console.log(session);
        await stripe.redirectToCheckout({ sessionId: session.id });
      },
    }
  };
  </script>
  
  <style scoped>
    .promo{

      .expired-message{
        display: flex;
        justify-content: space-between;
        color: white;
        padding: 10px;
        background-color: red;
      }
      .promo-price{
        display: flex;
        justify-content: space-between;
        color: white;
        padding: 10px;
        background-color: #F2B705;
      }
      p{
        margin: 0;
      }
    }
    .checkbox{
      width: 100%;
      display: flex;
      gap: 10px;
      align-items: center;
      justify-content: flex-end;
      padding-bottom: 20px;
      font-size: 16px;
      font-weight: 500;

      input{
        order: 2;
        margin: 0;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }
      label{
        order: 1;
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .programme{
      display: flex;
      flex-direction: column;
      margin-bottom: 1rem;
      border: 1px solid #c1c1c1;
      border-radius: 5px;

      .event{
        display: flex;
        justify-content: space-between;
        padding: 10px;
        border-bottom: 1px solid #c1c1c1;

        .price{
          font-weight: bolder;
        }
      }
      .total{
        display: flex;
        justify-content: space-between;
        padding: 10px;
        font-weight: bolder;
        color: #58A681;
      }
    }
    .coordonnees{
      font-size: 14px;

      h2{
        padding: 10px 0;
        margin: 0;
      }
      .infos{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #c1c1c1;

        strong{
          font-weight: bolder;
          text-align: right;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }
    .invoice{
      font-size: 14px;

      h2{
        padding: 10px 0;
        margin: 0;
      }

      .infos{
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        border-bottom: 1px solid #c1c1c1;

        strong{
          font-weight: bolder;
          text-align: right;
        }
        &:last-child{
          border-bottom: none;
        }
      }
    }

  </style>