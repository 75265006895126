<template>
  <div>
    <h1>Inscription annulée !</h1>
    <p>Nous espérons que vous n'avez pas changé d'avis.</p>
    <p>Vous pouvez reprendre votre inscription ci-dessous.</p>
  </div>
  <div class="actions">
    <router-link class="prevstep" to="/paiement">Retourner au résumé</router-link>
  </div>
</template>

<script>
export default {
  name: "CancelPage"
};
</script>