<template>
  <div>
    <h1>Une erreur est survenue !</h1>
    <p>Il est possible que le paiement soit passé mais qu'une erreur soit survenue après.</p>
    <p>Pas d'inquiétude, nous pouvons visualiser les réservation dans l'outil de paiement.</p>
  </div>
</template>

<script>
export default {
  name: "ErrorPage"
};
</script>