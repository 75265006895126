import { createRouter, createWebHistory } from 'vue-router';
import ProgramPage from '../components/ProgramPage.vue';
import FormPage from '../components/FormPage.vue';
import PaiementPage from '../components/PaiementPage.vue';
import SuccessPage from '../components/SuccessPage.vue';
import CancelPage from '../components/CancelPage.vue';
import ErrorPage from '../components/ErrorPage.vue';

const routes = [
  { path: '/', component: ProgramPage },
  { path: '/coordonnees', component: FormPage },
  { path: '/paiement', component: PaiementPage },
  { path: '/confirmation', component: SuccessPage },
  { path: '/annulation', component: CancelPage },
  { path: '/erreur', component: ErrorPage },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;