  <template>
    <h2>Coordonnées</h2>
    
    <div class="formulaire">
      <div class="input">
        <label for="firstname">Prénom :</label>
        <input v-model="formData.firstname" type="text" id="firstname" @input="changeDatas" required>
      </div>

      <div class="input">
        <label for="lastname">Nom :</label>
        <input v-model="formData.lastname" type="text" id="lastname" @input="changeDatas" required>
      </div>


      <div class="input">
        <label for="email">Email :</label>
        <input v-model="formData.email" type="email" id="email" @input="changeDatas" required>
      </div>

      <div class="input">
        <label for="phone">Numéro de téléphone :</label>
        <input v-model="formData.phone" type="tel" id="phone" @input="changeDatas" required>
      </div>

      <div class="input">
        <label for="section">Section Locale / Région :</label>
        <input v-model="formData.section" type="text" id="section" @input="changeDatas" required>
      </div>

      <div class="checkbox" style="padding-top: 0;">
        <label for="firsttimer">Première participation à des Assises</label>
        <input type="checkbox" id="firsttimer" v-model="formData.firsttimer" @change="changeDatas">
      </div>

      <div class="input">
        <label for="address">Adresse :</label>
        <input v-model="formData.address" type="text" id="address" @input="changeDatas" required>
      </div>

      <div class="input">
        <label for="postalCode">Code Postal :</label>
        <input v-model="formData.postalCode" type="text" id="postalCode" @input="changeDatas" required>
      </div>

      <div class="input">
        <label for="city">Ville :</label>
        <input v-model="formData.city" type="text" id="city" @input="changeDatas" required>
      </div>

      <div class="checkbox">
        <label for="invoice">Avec facture</label>
        <input type="checkbox" id="invoice" v-model="formData.invoice" @change="changeDatas">
      </div>
      
      <div class="input" v-if="formData.invoice">
        <label for="invoiceName">Nom de la société :</label>
        <input v-model="formData.invoiceName" type="text" id="invoiceName" @input="changeDatas" required>
      </div>

      <div class="input" v-if="formData.invoice">
        <label for="invoiceTVA">Numéro de TVA :</label>
        <input v-model="formData.invoiceTVA" type="text" id="invoiceTVA" @input="changeDatas">
      </div>

      <div class="input" v-if="formData.invoice">
        <label for="invoiceAddress">Adresse de la société :</label>
        <input v-model="formData.invoiceAddress" type="text" id="invoiceAddress" @input="changeDatas" required>
      </div>

      <div class="input" v-if="formData.invoice">
        <label for="invoicePostalCode">Code Postal de la société :</label>
        <input v-model="formData.invoicePostalCode" type="text" id="invoicePostalCode" @input="changeDatas" required>
      </div>

      <div class="input" v-if="formData.invoice">
        <label for="invoiceCity">Ville de la société :</label>
        <input v-model="formData.invoiceCity" type="text" id="invoiceCity" @input="changeDatas" required>
      </div>

    </div>

    <div class="actions">
      <router-link class="prevstep" to="/">Retour</router-link>
      <router-link v-if="checkFormComplete()" class="nextstep" to="/paiement">Étape suivante</router-link>
      <button v-else class="nextstep freeze">Étape suivante</button>
    </div>
  </template>
  
  <script>
  
  export default {
    props: {
      formData: {
        type: Object,
        required: true
      },
      events: {
        type: Object,
        required: true
      },
      updateDatasUser: {
        type: Function,
        required: true
      }
    },
    data() {
      return {
        // formData: {
        //   firstname: '',
        //   lastname: '',
        //   email: '',
        //   phone: '',
        //   section: '',
        //   address: '',
        //   postalCode: '',
        //   city: '',
        //   invoice: false,
        //   invoiceName: '',
        //   invoiceTVA: '',
        //   invoiceAddress: '',
        //   invoicePostalCode: '',
        //   invoiceCity: '',
        //   selectedEvents: []
        // },
      };
    },
    created() {
      if (!this.formData.selectedEvents || this.formData.selectedEvents.length === 0) {
        this.$router.push('/');
      }
    },
    methods: {
      changeDatas() {
        this.updateDatasUser(this.formData);
      },
      checkFormComplete() {
        const entriesPublic = this.formData.firstname && this.formData.lastname && this.formData.email && this.formData.phone && this.formData.section && this.formData.address && this.formData.postalCode && this.formData.city;
        const entriesPro = (!this.formData.invoice) || ( this.formData.invoiceName && this.formData.invoiceAddress && this.formData.invoicePostalCode && this.formData.invoiceCity);
        return entriesPublic && entriesPro;
      }
    }
  };
  </script>
  
  <style scoped>
    .formulaire{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    
      .input{
        display: flex;
        position: relative;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        label{
          position: absolute;
          top: 9px;
          left: 11px;
          font-size: 12px;
          font-style: italic;
        }
        input{
          width: 100%;
          height: 50px;
          border: 1px solid #ccc;
          border-radius: 5px;
          box-sizing: border-box;
          padding: 15px 10px 0px 10px;
          margin-bottom: 10px;
          font-size: 16px;
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
          background-color: #fff;
          box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
          transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);

          &:focus{
            outline: none;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0,, 0, 0.24);
            border: 1px solid #ccc;
          }
        }
      }
      .checkbox{
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding: 20px 0px;
        font-size: 16px;
        font-weight: 500;

        input{
          order: 1;
          margin: 0;
          width: 20px;
          height: 20px;
          cursor: pointer;
        }
        label{
          order: 2;
          font-size: 14px;
          font-weight: 500;
          margin-left: 10px;
          cursor: pointer;
        }
      }
    }

  </style>