  <template>
    <h2>Programme</h2>
    <div v-if="promoInfos" class="promo" :class="promoInfos.expired ? 'expired' : ''">
      <div class="expired-message" v-if="promoInfos.expired">
        <p>Code promo expiré</p>
      </div>
      <div class="promo-code" v-else>
        <p>-10€ pour le programme complet</p>
        <p>Temps restant : {{ promoInfos.timeLeft }}</p>
      </div>
    </div>
    <div v-if="formData.tarifs=='earlybird'" class="promo">
      <div class="promo-code">
        <p>Accès tarif earlybird</p>
        <p>Vous ne pouvez en profiter que si vous êtes<br /> bien inscrit et confirmé à ce tarif, un supplément<br /> sera demandé dans le cas contraire.</p>
      </div>
    </div>
    <div class="events">
      <div v-for="(event, index) in events" :key="index" class="event" :class="checkLockedEvents(event) + ' ' +(formData.selectedEvents.includes(index) ? 'selected' : '')">
        <label>
          <input type="checkbox" :value="index" v-model="formData.selectedEvents" @change="changeDatas(index)">
          <span class="name">{{ event.name }}</span>
          <span class="price" :class="formData.tarifs">
            <span class="earlybird"><i>Earlybird</i> <strong>{{ event.earlybird }}€</strong></span>
            <span class="regular"><i>prix normal</i> <strong>{{ event.regular }}€</strong></span>
            <span class="late"><i>Après le 1er mars</i> <strong>{{ event.late }}€</strong></span>
          </span>
        </label>
      </div>
    </div>
    <div class="actions">
      <router-link v-if="formData.selectedEvents.length" class="nextstep" to="/coordonnees">Étape suivante</router-link>
      <button v-else class="nextstep freeze">Étape suivante</button>
    </div>
  </template>
  
  <script>

  export default {
    props: {
      formData: {
        type: Object,
        required: true
      },
      events: {
        type: Object,
        required: true
      },
      promoInfos: {
        type: Object,
        required: true
      },
      updateDatasUser: {
        type: Function,
        required: true
      }
    },
    data() {
      return {

      };
    },
    methods: {
      changeDatas(event) {
        if (this.events[event].lock.length > 0) {
          this.formData.selectedEvents = this.formData.selectedEvents.filter(selectedEvent => !this.events[event].lock.includes(selectedEvent));
        }
        this.updateDatasUser(this.formData);
      },
      checkLockedEvents(event) {
        if (this.formData.selectedEvents.some(selectedEvent => event.lock.includes(selectedEvent))) {
          return 'locked';
        }
        return '';
      },
    }
  };
  </script>
  
  <style scoped>
    .promo{
      align-items: center;
      padding: 2px 20px;
      background-color: #F2B705;
      border-radius: 5px;
      margin-bottom: 10px;
      font-size: 16px;
      font-weight: 600;
      padding: 10px;
      color: white;
      text-align: center;
      
      p{
        margin: 5px 0;
      }
    } 
    .events{
      display: flex;
      flex-direction: column;
      gap: 10px;
      text-align: center;

      .event{
        position: relative;
        overflow: hidden;

        input{
          opacity: 0;
          position: absolute;
          left: -500px;
          top: 0;
        }
        label{
          display: block;
          cursor: pointer;
          border: 1px solid #c1c1c1;
          border-radius: 5px;
          padding: 10px;
          transition: 0.3s ease-in-out;

          .price{
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 13px;
            font-weight: 600;
            margin-top: 10px;
            font-size: 20px;

            span{
              width: 33%;
            }
            i{
              display: block;
              font-size: 12px;
              text-decoration: none !important;
            }
            .earlybird{
              opacity: 0.4;
              font-size: 14px;
              strong{
                font-weight: 400;
                text-decoration: line-through;
              }
            }
            .late{
              color: #F2B705;
              font-size: 20px;
              strong{
                font-weight: 400;
                text-decoration: line-through;
              }
            }

            &.earlybird{
              .earlybird{
                opacity: 1;
                font-size: 20px;

                strong{
                  font-weight: 600;
                  text-decoration: none !important;
                }
              }
              .regular{
                opacity: 0.4;
                font-size: 14px;
                strong{
                  font-weight: 400;
                  text-decoration: line-through;
                }
              }
            }
          }
        }
        &.selected label{
          background: #7B6DB0;
          border-color: #7B6DB0;
          color: white;

          &:hover{
            background: #7B6DB0;
          }
        }
        &.locked label{
          opacity: 0.3;
        }
      }
    }
  </style>